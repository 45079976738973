import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// plug-ins
import 'moment/locale/ru';

// screens
import Start				from './Screens/Start';
import Login				from './Screens/Login';

// books
import Products				from './Screens/Books/Products';
import Categories			from './Screens/Books/Categories';
import ProductOptionGroups	from './Screens/Books/ProductOptionGroups';
import ProductOptions		from './Screens/Books/ProductOptions';
import Clients				from './Screens/Books/Clients';
import Suppliers			from './Screens/Books/Suppliers';
import Components			from './Screens/Books/Components';
import Semifinishes			from './Screens/Books/Semifinishes';
import Reasons				from './Screens/Books/Reasons';
import ExpenseCategories	from './Screens/Books/ExpenseCategories';
import Labels				from './Screens/Books/Labels';
import Tags					from './Screens/Books/Tags';
import Sources				from './Screens/Books/Sources';
import Statuses				from './Screens/Books/Statuses';
import Users				from './Screens/Books/Users';
import Roles				from './Screens/Books/Roles';
import Storages				from './Screens/Books/Storages';
import Workshops			from './Screens/Books/Workshops';

// statistics
import Statistics			from './Screens/Statistics/Statistics';
import WorkTime				from './Screens/Statistics/WorkTime';
import DayShifts			from './Screens/Statistics/DayShifts';
import Reports				from './Screens/Statistics/Reports';

// orders
import OrderAdd				from './Screens/Orders/OrderAdd';
import Orders				from './Screens/Orders/Orders';
import OrdersArchive		from './Screens/Orders/OrdersArchive';

// marketing
import Promocodes			from './Screens/Marketing/Promocodes';
import Loyalities			from './Screens/Marketing/Loyalities';
import LoyalityTransactions	from './Screens/Marketing/LoyalityTransactions';
import Actions				from './Screens/Marketing/Actions';
import Basket				from './Screens/Marketing/Basket';
import Events				from './Screens/Marketing/Events';
import Referrals			from './Screens/Marketing/Referrals';
import Pushes				from './Screens/Marketing/Pushes';
import Sms					from './Screens/Marketing/Sms';
import Feedbacks			from './Screens/Marketing/Feedbacks';

// warehouse
import Invoices				from './Screens/Warehouse/Invoices';
import Writeoffs			from './Screens/Warehouse/Writeoffs';
import Releases				from './Screens/Warehouse/Releases';
import Movements			from './Screens/Warehouse/Movements';
import Balances				from './Screens/Warehouse/Balances';
import Inventories			from './Screens/Warehouse/Inventories';

// money
import Expenses				from './Screens/Money/Expenses';
import Cashbox				from './Screens/Money/Cashbox';
import Salary				from './Screens/Money/Salary';

// account
import Account				from './Screens/Account/Account';
import DeliveryAreas		from './Screens/Account/DeliveryAreas';
import Points				from './Screens/Account/Points';
import Areas				from './Screens/Account/Areas';

// settings
import Settings				from './Screens/Settings/Settings';
import SettingsAdmin		from './Screens/Settings/SettingsAdmin';

// support
import Support				from './Screens/Support/Support';

import Error				from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/login' component={Login} exact />

			<Route path='/order-add' component={OrderAdd} exact />
			<Route path='/order-add/:id' component={OrderAdd} exact />
			<Route path='/orders' component={Orders} exact />
			<Route path='/order/:id' component={Orders} exact />
			<Route path='/order-edit/:id' component={OrderAdd} exact />
			<Route path='/orders/archive' component={OrdersArchive} exact />

			<Route path='/statistics' component={Statistics} exact />
			<Route path='/statistics/worktime' component={WorkTime} exact />
			<Route path='/statistics/worktime/:id' component={WorkTime} exact />
			<Route path='/statistics/dayshifts' component={DayShifts} exact />
			<Route path='/statistics/reports' component={Reports} exact />
			<Route path='/statistics/reports/:target' component={Reports} exact />

			<Route path='/books/products' component={Products} exact />
			<Route path='/books/product/:id' component={Products} exact />
			<Route path='/books/categories' component={Categories} exact />
			<Route path='/books/options' component={ProductOptions} exact />
			<Route path='/books/optiongroups' component={ProductOptionGroups} exact />
			<Route path='/books/clients' component={Clients} exact />
			<Route path='/books/client/:id' component={Clients} exact />
			<Route path='/books/suppliers' component={Suppliers} exact />
			<Route path='/books/components' component={Components} exact />
			<Route path='/books/semifinishes' component={Semifinishes} exact />
			<Route path='/books/reasons' component={Reasons} exact />
			<Route path='/books/expensecategories' component={ExpenseCategories} exact />
			<Route path='/books/users' component={Users} exact />
			<Route path='/books/user/:id' component={Users} exact />
			<Route path='/books/labels' component={Labels} exact />
			<Route path='/books/label/:id' component={Labels} exact />
			<Route path='/books/tags' component={Tags} exact />
			<Route path='/books/sources' component={Sources} exact />
			<Route path='/books/statuses' component={Statuses} exact />
			<Route path='/books/roles' component={Roles} exact />
			<Route path='/books/storages' component={Storages} exact />
			<Route path='/books/workshops' component={Workshops} exact />

			<Route path='/marketing/promocodes' component={Promocodes} exact />
			<Route path='/marketing/promocode/:id' component={Promocodes} exact />
			<Route path='/marketing/loyalty' component={Loyalities} exact />
			<Route path='/marketing/loyalty/:id' component={Loyalities} exact />
			<Route path='/marketing/bonuses' component={LoyalityTransactions} exact />
			<Route path='/marketing/actions' component={Actions} exact />
			<Route path='/marketing/action/:id' component={Actions} exact />
			<Route path='/marketing/basket' component={Basket} exact />
			<Route path='/marketing/events' component={Events} exact />
			<Route path='/marketing/referrals' component={Referrals} exact />
			<Route path='/marketing/pushes' component={Pushes} exact />
			<Route path='/marketing/sms' component={Sms} exact />
			<Route path='/marketing/feedbacks' component={Feedbacks} exact />

			<Route path='/warehouse/invoices' component={Invoices} exact />
			<Route path='/warehouse/writeoffs' component={Writeoffs} exact />
			<Route path='/warehouse/releases' component={Releases} exact />
			<Route path='/warehouse/movements' component={Movements} exact />
			<Route path='/warehouse/balances' component={Balances} exact />
			<Route path='/warehouse/inventories' component={Inventories} exact />

			<Route path='/money/cashbox' component={Cashbox} exact />
			<Route path='/money/expenses' component={Expenses} exact />
			<Route path='/money/salary' component={Salary} exact />

			<Route path='/account' component={Account} exact />
			<Route path='/account/tariff' component={Account} exact />
			<Route path='/account/tariff/success' component={Account} exact />
			<Route path='/account/tariff/fail' component={Account} exact />
			<Route path='/account/deliveryareas' component={DeliveryAreas} exact />
			<Route path='/account/points' component={Points} exact />
			<Route path='/account/areas' component={Areas} exact />

			<Route path='/settings' component={Settings} exact />
			<Route path='/settings/:target' component={Settings} exact />
			<Route path='/settings/admin/:target' component={SettingsAdmin} exact />

			<Route path='/support' component={Support} exact />
			<Route path='/support/:id' component={Support} exact />
			<Route path='/support/feedback' component={Support} exact />

			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;