import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import Moment from 'moment';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import {useInput} from '../../../Components/Hooks/Input';
import Error from '../../../Components/Error';
import Layout from '../../../Components/Layout';
import OrderInfo from '../../../Components/OrderInfo';
import Alert,{errorShow} from '../../../Components/Alert';

// models
import {Categories, Clients, Components, Products, Reports, Semifinishes, Sources, Storages, Users} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Sidemenus from '../../../Globals/Sidemenus';

// constants
import { reportPeriodType, reportPeriodTypeName, reportDetailTypeName, reportGraphTypeName, paymentTypeName, discountTypeName, sourceTypeName, userTypeName, orderStatus, orderStatusName, reportGraphType, measureTypeSmallName, measureTypeName, measureType, reportDetailTimeTypeName, reportDetailType, reportDetailTimeType } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';

// init
Chart.register(CategoryScale);


// start
const ReportsScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [ModalOrders, modalOrdersOpen, modalOrdersClose] = useModal('root');
	const [target, setTarget] = useState(null);
	const [data, setData] = useState(null);
	const [dataGraph, setDataGraph] = useState(null);
	const [tab, setTab] = useState(1);
	const [period, setPeriod] = useState(2);
	const {value:dateStart, setValue:setDateStart, bind:bindDateStart} = useInput('');
	const {value:dateEnd, setValue:setDateEnd, bind:bindDateEnd} = useInput('');
	const [details, setDetails] = useState('');
	const [graphic, setGraphic] = useState(1);
	const [sources, setSources] = useState([]);
	const [source, setSource] = useState('');
	const [payment, setPayment] = useState('');
	const [status, setStatus] = useState('');
	const [discount, setDiscount] = useState('');
	const [sourceType, setSourceType] = useState('');
	const [delivery, setDelivery] = useState('');
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState('');
	const [products, setProducts] = useState([]);
	const [productId, setProductId] = useState(null);
	const [semifinishes, setSemifinishes] = useState([]);
	const [components, setComponents] = useState([]);
	const [storages, setStorages] = useState([]);
	const [storageId, setStorageId] = useState('');
	const [client, setClient] = useState(null);
	const {value:orderCountStart, setValue:setOrderCountStart, bind:bindOrderCountStart} = useInput('');
	const {value:orderCountEnd, setValue:setOrderCountEnd, bind:bindOrderCountEnd} = useInput('');
	const {value:orderAmountStart, setValue:setOrderAmountStart, bind:bindOrderAmountStart} = useInput('');
	const {value:orderAmountEnd, setValue:setOrderAmountEnd, bind:bindOrderAmountEnd} = useInput('');
	const [isNoOrders, setIsNoOrders] = useState(false);
	const [isNoPurchase, setIsNoPurchase] = useState(false);
	const [isShowGroup, setIsShowGroup] = useState(false);
	const [isComponentShow, setIsComponentShow] = useState(false);
	const [isFilterShow, setIsFilterShow] = useState(false);
	const [isGraphShow, setIsGraphShow] = useState(false);
	const [isError, setIsError] = useState(false);
	const [loadingReport, setLoadingReport] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const t = props.match.params.target||'revenue';
			if (t !== target) {
				setData(null);
				filterReset();
				setLoadingReport(false);
			}
			setTarget(t);
			await targetGet(t);
		};
		await dataGet();
		setLoading(false);
	}, [props]);
	const reportGet = async (target) => {
		const data = {
			target:target
		};
		switch (target) {
			case 'costprice':
				if (productId) data.productId = productId;
				const res1 = await Reports.get.costprice(data).catch((ex) => {
					console.log(666, ex);
					setIsError(true);
				});
				setData(res1);
				break;
			case 'clients':
				if (Utils.empty(dateStart) && Utils.empty(dateEnd)) data.period = period;
				else {
					data.dateStart = dateStart;
					data.dateEnd = dateEnd;
				}
				if (orderCountStart) data.orderCountStart = orderCountStart;
				if (orderCountEnd) data.orderCountEnd = orderCountEnd;
				if (orderAmountStart) data.orderAmountStart = orderAmountStart;
				if (orderAmountEnd) data.orderAmountEnd = orderAmountEnd;
				if (isNoOrders) data.isNoOrders = isNoOrders;
				const res3 = await Reports.get.clients(data).catch((ex) => {
					console.log(666, ex);
					setIsError(true);
				});
				setData(res3);
				break;
			case 'purchases':
			case 'raw':
			case 'abc':
			case 'profit':
				if (Utils.empty(dateStart) && Utils.empty(dateEnd)) data.period = period;
				else {
					data.dateStart = dateStart;
					data.dateEnd = dateEnd;
				}
				if (productId) data.productId = productId;
				if (storageId) data.storageId = storageId;
				const res2 = await Reports.get[target](data).catch((ex) => {
					console.log(666, ex);
					setIsError(true);
				});
				setData(res2);
				break;
			default:
				if (Utils.empty(dateStart) && Utils.empty(dateEnd)) data.period = period;
				else {
					data.dateStart = dateStart;
					data.dateEnd = dateEnd;
				}
				if (details) data.details = details;
				if (source) data.source = source;
				if (payment) data.payment = payment;
				if (status) data.status = status;
				if (discount) data.discount = discount;
				if (sourceType) data.sourceType = sourceType;
				if (delivery) data.delivery = delivery;
				if (user) data.user = user;
				if (isNoPurchase) data.isNoPurchase = isNoPurchase;
				const res = await Reports.get.orders(data).catch((ex) => {
					console.log(666, ex);
					setIsError(true);
				});
				setData(res.out);
				setDataGraph(res.full && res.full.length ? res.full : null);
				break;
		}
	};
	const targetGet = async (target) => {
		switch (target) {
			case 'channels':
				const sources = await Sources.get();
				setSources(sources);
				break;
			case 'hours':
				setDetails(reportDetailTimeType.HOUR);
				break;
			case 'users':
				const u = await Users.get();
				const users = u.reduce((a, b) => {
					const usr = {
						id:b._id,
						name:b.name
					};
					a[b.type] = a[b.type] ? a[b.type].concat(usr) : [usr];
					return a;
				}, {});
				setUsers(users);
				break;
			case 'costprice':
				const products = await Products.get.all();
				const categories = await Categories.get.all();
				const semifinishes = await Semifinishes.get();
				const p = {};
				products.reduce((a, b) => {
					const category = categories.find(f => f._id === b.categoryId);
					b.categoryName = category ? category.name : '';
					a[b.categoryId] = a[b.categoryId] ? a[b.categoryId].concat(b) : [b];
					return a;
				}, p);
				setProducts(p);
				setSemifinishes(semifinishes);
				break;
			case 'purchases':
			case 'raw':
				const productsstore = await Products.get.store();
				const components = await Components.get.store();
				const semifinishes2 = await Semifinishes.get();
				setProducts(productsstore);
				setComponents(components);
				setSemifinishes(semifinishes2);
			case 'profit':
				const storages = await Storages.get();
				setStorages(storages);
				break;
			default: break;
		};
	};
	const tabSet = (tab) => setTab(tab);
	const tabsBlock = <div className="categories-tabs">
		<div className="tabs">
			<div className={`tab${tab===0?' active':''}`} onClick={() => tabSet(0)}>Разделы</div>
			<div className={`tab${tab===1?' active':''}`} onClick={() => tabSet(1)}>Отчеты</div>
		</div>
	</div>;
	const isselect = (link) => link === window.location.pathname;
	const periodSet = (period) => {
		if (target === 'hours') {
			if (detailTimeTypeIsDisabled(details, period)) setDetails(reportDetailTimeType.HOUR);
		} else {
			if (detailTypeIsDisabled(details, period)) setDetails('');
		}
		if (target === 'profit') setStorageId(storages[0]._id);
		setPeriod(period);
		setData(null);
		datesClear();
	};
	const datesClear = () => {
		setDateStart('');
		setDateEnd('');
	};
	const titleGet = () => {
		const def = Sidemenus.reports[0].items[0].name;
		if (Utils.empty(target)) return def;
		const title = Sidemenus.reports.map(v => v.items).flat(Infinity).find(f => f.link === `/statistics/reports/${target}`);
		return title ? title.name : def;
	};
	const reportShow = async () => {
		setLoadingReport(true);
		filterClose();
		await reportGet(target);
		setLoadingReport(false);
		setIsGraphShow(true);
	};
	const filterShow = (e) => {
		e.stopPropagation();
		setIsFilterShow(!isFilterShow);
	};
	const filterClose = () => setIsFilterShow(false);
	const filterStop = (e) => e.stopPropagation();
	const filterReset = () => {
		setSource('');
		setPayment('');
		setStatus('');
		setDiscount('');
		setDelivery('');
		setUser('');
		if (target === 'profit') setStorageId(storages[0]._id);
		else setStorageId('');
		if (target === 'hours') setDetails(reportDetailTimeType.HOUR);
		setSourceType(0);
		setOrderCountStart('');
		setOrderCountEnd('');
		setOrderAmountStart('');
		setOrderAmountEnd('');
		setIsNoPurchase(false);
		setIsNoOrders(false);
		setIsShowGroup(false);
		setIsComponentShow(false);
		setDetails('');
		setGraphic(1);
		setIsGraphShow(false);
	};
	const filterApply = () => reportShow();
	const detailTypeIsDisabled = (type, p) => {
		p = p || period;
		switch (type) {
			case reportDetailType.DAY: return ![reportPeriodType.TOMORROW,reportPeriodType.TODAY,reportPeriodType.YESTERDAY,reportPeriodType.WEEK,reportPeriodType.MONTH].includes(p);
			case reportDetailType.WEEK: return ![reportPeriodType.MONTH,reportPeriodType.YEAR].includes(p);
			case reportDetailType.MONTH: return ![reportPeriodType.YEAR].includes(p);
			default: return false;
		};
	};
	const detailTimeTypeIsDisabled = (type, p) => {
		p = p || period;
		switch (type) {
			case reportDetailTimeType.HOUR: return false;
			case reportDetailTimeType.WEEKDAY: return ![reportPeriodType.WEEK,reportPeriodType.MONTH,reportPeriodType.YEAR].includes(p);
			default: return false;
		};
	};
	const targetDataShow = () => {
		const detailsRow = <div className="filter-row">
			<label>Детализация</label>
			<div className="select">
				<select onChange={detailsHandle} value={details}>
					<option>Нет</option>
					{reportDetailTypeName.map((v,i) => {
						if (i === 0) return null;
						const disabled = detailTypeIsDisabled(i);
						return <option key={i} value={i} disabled={disabled}>{v}</option>;
					})}
				</select>
			</div>
		</div>;
		const detailsTimeRow = <div className="filter-row">
			<label>Группировка</label>
			<div className="select">
				<select onChange={detailsHandle} value={details}>
					{reportDetailTimeTypeName.map((v,i) => {
						if (i === 0) return null;
						const disabled = detailTimeTypeIsDisabled(i);
						return <option key={i} value={i} disabled={disabled}>{v}</option>;
					})}
				</select>
			</div>
		</div>;
		const graphRow = <div className="filter-row">
			<label>График</label>
			<div className="select">
				<select onChange={e => setGraphic(e.target.value)} value={graphic}>
					{reportGraphTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
				</select>
			</div>
		</div>;
		switch (target) {
			case 'revenue':
				return <>
					{detailsRow}
					{details ? graphRow : null}
					<div className="filter-row">
						<label>Тип оплаты</label>
						<div className="select">
							<select onChange={paymentHandle} value={payment}>
								<option value="">Все</option>
								{paymentTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
							</select>
						</div>
					</div>
				</>;
			case 'rejects':
				return <>
					{detailsRow}
					{details ? graphRow : null}
					<div className="filter-row">
						<label>Статус</label>
						<div className="select">
							<select onChange={rejectHandle} value={status}>
								<option value="">Все</option>
								<option value={orderStatus.CANCELED}>{orderStatusName[orderStatus.CANCELED]}</option>
								<option value={orderStatus.REFUND}>{orderStatusName[orderStatus.REFUND]}</option>
							</select>
						</div>
					</div>
				</>;
			case 'discount':
				return <>
					{detailsRow}
					{details ? graphRow : null}
					<div className="filter-row">
						<label>Тип скидки</label>
						<div className="select">
							<select onChange={discountHandle} value={discount}>
								<option value="">Все</option>
								{discountTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
							</select>
						</div>
					</div>
				</>;
			case 'sources':
				return <>
					{detailsRow}
					{details ? graphRow : null}
					<div className="filter-row">
						<label>Источник заказа</label>
						<div className="select">
							<select onChange={sourceTypeHandle} value={sourceType}>
								<option value="">Все</option>
								{sourceTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
							</select>
						</div>
					</div>
				</>;
			case 'delivery':
				return <>
					{detailsRow}
					{details ? graphRow : null}
					<div className="filter-row">
						<label>Тип доставки</label>
						<div className="select">
							<select onChange={deliveryHandle} value={delivery}>
								<option value="">Все</option>
								<option value="delivery">Доставка</option>
								<option value="pickup">Самовывоз</option>
							</select>
						</div>
					</div>
				</>;
			case 'channels':
				return <>
					{detailsRow}
					{details ? graphRow : null}
					<div className="filter-row">
						<label>Каналы продаж</label>
						<div className="select">
							<select onChange={sourceHandle} value={source}>
								<option value="">Все</option>
								{sources.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
							</select>
						</div>
					</div>
				</>;
			case 'time':
				return <>
					<div className="filter-row filter-row-column">
						<div className="select">
							<select onChange={userHandle} value={user}>
								<option value="">Все сотрудники</option>
								{Object.keys(users).map((v,i) => <optgroup key={i} label={userTypeName[v]}>
									{users[v].map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
								</optgroup>)}
							</select>
						</div>
					</div>
				</>;
			case 'hours':
				return <>
					{detailsTimeRow}
					{graphRow}
				</>;
			case 'users':
				return <>
					{detailsRow}
					{details ? graphRow : null}
					<div className="filter-row filter-row-column">
						<div className="select">
							<select onChange={userHandle} value={user}>
								<option value="">Все сотрудники</option>
								{Object.keys(users).map((v,i) => <optgroup key={i} label={userTypeName[v]}>
									{users[v].map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
								</optgroup>)}
							</select>
						</div>
					</div>
				</>;
			case 'clientsorders':
				return <>
					{detailsRow}
					{details ? graphRow : null}
				</>;
			case 'clients':
				return <>
					<div className="filter-row">
						<label>Кол-во заказов</label>
						<div className="inputs">
							<input type="text" {...bindOrderCountStart} maxLength={3} placeholder="от" />
							<input type="text" {...bindOrderCountEnd} maxLength={3} placeholder="до" />
						</div>
					</div>
					<div className="filter-row">
						<label>Сумма заказов</label>
						<div className="inputs">
							<input type="text" {...bindOrderAmountStart} maxLength={6} placeholder="от" />
							<input type="text" {...bindOrderAmountEnd} maxLength={6} placeholder="до" />
						</div>
					</div>
					<div className="filter-row">
						<label>Не было заказов</label>
						<img src={isNoOrders ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsNoOrders(!isNoOrders)} />
					</div>
				</>;
			case 'clientsnew':
				return <>
					{detailsRow}
				</>;
			case 'products':
				return <>
					<div className="filter-row">
						<label>Не было продаж</label>
						<img src={isNoPurchase ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsNoPurchase(!isNoPurchase)} />
					</div>
				</>;
			case 'costprice':
				return <>
					<div className="filter-row">
						<label>Показать состав</label>
						<img src={isComponentShow ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsComponentShow(!isComponentShow)} />
					</div>
					<div className="filter-row filter-row-column">
						<div className="select">
							<select onChange={productHandle} value={productId}>
								<option value="">Все товары</option>
								{Object.keys(products).map((v,i) => <optgroup key={i} label={products[v][0].categoryName}>
									{products[v].map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
								</optgroup>)}
								{semifinishes.length ?
									<optgroup label="Полуфабрикаты">
										{semifinishes.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
									</optgroup> : null}
							</select>
						</div>
					</div>
				</>;
			case 'purchases':
			case 'raw':
				return <>
					<div className="filter-row filter-row-column">
						<div className="select">
							<select onChange={productHandle} value={productId}>
								<option value="">Все</option>
								<optgroup label="Компоненты">
									{components.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
								</optgroup>
								{semifinishes.length ?
									<optgroup label="Полуфабрикаты">
										{semifinishes.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
									</optgroup> : null}
								{products.length ?
									<optgroup label="Товары">
										{products.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
									</optgroup> : null}
							</select>
						</div>
					</div>
					<div className="filter-row filter-row-column">
						<div className="select">
							<select onChange={storageHandle} value={storageId}>
								<option value="">Все склады</option>
								{storages.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
							</select>
						</div>
					</div>
				</>;
			case 'profit':
				return <>
					<div className="filter-row filter-row-column">
						<div className="select">
							<select onChange={storageHandle} value={storageId}>
								{storages.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
							</select>
						</div>
					</div>
				</>;
			case 'abc':
				return <>
					<div className="filter-row">
						<label>Названия групп</label>
						<img src={isShowGroup ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsShowGroup(!isShowGroup)} />
					</div>
				</>;
			default: return null;
		};
	};
	const detailsHandle = (e) => {
		setIsGraphShow(false);
		setDetails(e.target.value ? parseInt(e.target.value) : '');
	}
	const storageHandle = (e) => setStorageId(e.target.value);
	const productHandle = (e) => setProductId(e.target.value);
	const sourceHandle = (e) => setSource(e.target.value);
	const paymentHandle = (e) => setPayment(e.target.value);
	const rejectHandle = (e) => setStatus(e.target.value);
	const discountHandle = (e) => setDiscount(e.target.value);
	const sourceTypeHandle = (e) => setSourceType(e.target.value);
	const deliveryHandle = (e) => setDelivery(e.target.value);
	const userHandle = (e) => setUser(e.target.value);
	const totalSumGet = () => data ? Utils.numFix(data.reduce((a,v) => a + v.sum, 0)) : 0;
	const totalMedianGet = () => data ? Utils.numFix(data.reduce((a,v) => a + v.median, 0)) : 0;
	const totalCountGet = () => data ? Utils.numFix(data.reduce((a,v) => a + v.count, 0)) : 0;
	const chartOptions = {
		plugins: {
			legend: {
				display:false
			}
		},
		scales: {
			y: {
				beginAtZero:false
			},
			x: {
				grid: {
					display:false
				}
			}
		}
	};
	const chartLabelsGet = () => data.map((v,i) => Utils.yearRemove(v.title));
	const chartDatasetGet = () => {
		if (dataGraph) {
			const d = [], dataset = [];
			dataGraph.forEach((v,i) => {
				v.data.forEach((v2,i) => {
					d[i] = d[i] || [];
					d[i].push(v2);
				});
			});
			d.forEach((v,i) => {
				const color = Utils.chartColorGet(i);
				dataset.push({
					label:v[0].title,
					data:chartDataGet(v),
					pointBackgroundColor:color,
					borderColor:`${color}80`
				});
			});
			chartOptions.plugins.legend.display = true;
			return dataset;
		} else {
			chartOptions.plugins.legend.display = false;
			return [{data:chartDataGet(data)}];
		}
	};
	const chartDataGet = (data) => {
		const d = [];
		data.forEach((v,i) => {
			switch (parseInt(graphic)) {
				case reportGraphType.SUM: d.push(Utils.numFix(v.sum)); break;
				case reportGraphType.AVERAGE: d.push(Utils.numFix(v.median)); break;
				case reportGraphType.ORDER: default: d.push(Utils.numFix(v.count)); break;
			};
		});
		return d;
	};
	const isGraphHide = () => [reportPeriodType.TOMORROW,reportPeriodType.TODAY,reportPeriodType.YESTERDAY].includes(period);
	const productsCount = (products) => products.reduce((a,v) => a + v.count, 0);
	const productsSectionSum = (products) => products.reduce((a,v) => a + v.price, 0);
	const productsTotalSum = (items) => {
		let sum = 0;
		items.forEach((v) => sum += productsSectionSum(v.products));
		return Utils.numFix(sum);
	};
	const controlsHide = () => ['costprice'].includes(target);
	const invoicesCount = (invoices) => invoices.reduce((a,v) => a + v.items.reduce((a,v) => a + v.quantity, 0), 0);
	const invoicesSum = (invoices) => invoices.reduce((a,v) => a + v.total, 0);
	const invoicesProductSum = (invoices) => invoices.reduce((a,v) => a + v.items.reduce((a,v) => a + v.total, 0), 0);
	const rowGet = (v, i) => <tr key={i}>
		<td>{v.name}</td>
		<td className="status status-small">{measureTypeName[v.measure]}</td>
		<td className="amount">{v.income}</td>
		<td className="amount">{v.release}</td>
		<td className="amount">{v.expense}</td>
		<td className="amount">{v.writeoff}</td>
	</tr>;
	const timeGet = (time) => {
		if (time > 60 && time < 1440) return `${Math.floor(time / 60)} ч. ${time % 60} мин.`;
		if (time >= 1440) return `${Math.floor(time / 1440)} д. ${Math.floor((time % 1440) / 60)} ч.`;
		return `${time} мин.`;
	};
	const modalOrderHistoryShow = async (client) => {
		const res = await Clients.get.orders(client.id, client.ids).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			setClient(res);
			modalOrdersOpen();
		}
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title={titleGet()}
							type="statistics"
							click={filterClose}
							contentTabs={tab === 0 ? tabsBlock : null}
							contentSideMenu={tab === 1 ?
								<>
									<div className="categories-list-container">
										{tabsBlock}
										<div className="container-inner">
											<div className="list">
												{Sidemenus.reports.map((v,i) => <div key={i} className="category-section">
													<div className="category-section-title">{v.name}</div>
													{v.items.map((v,i) => <Link key={i} to={v.link} className={`category${isselect(v.link)?' category-select':''}`}>{v.name}</Link>)}
												</div>)}
											</div>
										</div>
									</div>
								</>
							: null}
							contentRows={<>
								<div className="reports-container">
									<div className="controls">
										{controlsHide() ? null :
											<>
												<div className="tabs">
													{reportPeriodTypeName.map((v,i) => i === 0 ? null : <div key={i} className={`tab${period===i&&Utils.empty(dateStart)&&Utils.empty(dateEnd)?' tab-active':''}`} onClick={() => periodSet(i)}>{v}</div>)}
												</div>
												<div className="dates">
													с
													<input type="date" {...bindDateStart} className={`${!Utils.empty(dateStart)?'active':''}`} />
													по
													<input type="date" {...bindDateEnd} className={`${!Utils.empty(dateEnd)?'active':''}`} />
												</div>
											</>
										}
										<div className="button">
											<div className="btn-action" onClick={reportShow}>Показать</div>
											<div className={`btn-arrowdown${isFilterShow?' btn-arrowdown-open':''}`} onClick={filterShow}></div>
										</div>
										{isFilterShow ?
											<div className="filter" onClick={filterStop}>
												<h4>Настройки</h4>
												<img src={imgClose} alt="" className="close" onClick={filterClose} />
												{targetDataShow()}
												<div className="buttons">
													<button type="button" className="btn-second" onClick={filterReset}>Сбросить</button>
													<button type="button" onClick={filterApply}>Применить</button>
												</div>
											</div> : null}
									</div>
									<div className="main">
										{loadingReport ? <Loader /> :
											(data === null ?
													<div className="empty">
														<Empty image={'statistic'} text={<>Для отображения отчета<br/>выберите параметры и нажмите <b>Показать</b></>} />
													</div>
												:
													(data.length ?
															<>
																{target === 'hours' || (details && isGraphShow && !isGraphHide()) ?
																		<div className="chart">
																			{target === 'hours' ?
																					<Bar
																						width={'100%'}
																						height={40}
																						data={{
																							labels:chartLabelsGet(),
																							datasets:chartDatasetGet()
																						}}
																						options={{
																							borderRadius:4,
																							barPercentage:.7,
																							hoverBackgroundColor:'#89baeb',
																							borderSkipped:'bottom',
																							...chartOptions
																						}}
																					/>
																				:
																					<Line
																						width={'100%'}
																						height={40}
																						data={{
																							labels:chartLabelsGet(),
																							datasets:chartDatasetGet()
																						}}
																						options={{
																							pointBackgroundColor:'#2792fb',
																							pointBorderColor:'#ffffff',
																							pointBorderWidth:2,
																							pointRadius:4,
																							borderColor:'#2792fb80',
																							tension:.2,
																							...chartOptions
																						}}
																					/>
																			}
																		</div>
																	: null}
																{target === 'clients' ?
																		<table className="items items-no-control items-clients">
																			<thead>
																				<tr>
																					<th>Имя</th>
																					<th className="phone">Номер телефона</th>
																					<th>Адрес</th>
																					<th className="discount">Скидка</th>
																					<th className="amount">Заказов</th>
																					<th className="amount">Сумма</th>
																					<th className="datetime">Посл.заказ</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <tr key={i} onClick={() => modalOrderHistoryShow(v)}>
																					<td>
																						{v.name||'–'}
																						{v.comment ? <span>{v.comment}</span> : null}
																					</td>
																					<td className="phone">{Utils.phoneFormatter(v.phone)}</td>
																					<td>{v.address||'–'}</td>
																					<td className="discount">{v.discount ? <>{v.discount}%</> : '–'}</td>
																					<td className="amount">{v.orders}</td>
																					<td className="amount">{Utils.numFix(v.total)}</td>
																					<td className="datetime">{v.lastorder ? Moment(v.lastorder).format('DD.MM.YYYY HH:mm') : '–'}</td>
																				</tr>)}
																			</tbody>
																		</table>
																	: null}
																{target === 'clientsnew' ?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Наименование</th>
																					<th className="prices">Количество</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <tr key={i}>
																					<td>Регистрация</td>
																					<td className="prices">{Utils.numFix(v.count)}</td>
																				</tr>)}
																				<tr className="info-row">
																					<td>Всего</td>
																					<td className="prices">{totalCountGet()}</td>
																				</tr>
																			</tbody>
																		</table>
																	: null}
																{target === 'products' ?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Наименование</th>
																					<th className="prices">Количество</th>
																					<th className="prices-mid">Цена</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <React.Fragment key={i}>
																					<tr className="section">
																						<td>{v.name}</td>
																						<td className="prices">{productsCount(v.products)}</td>
																						<td className="prices-mid">{Utils.numFix(productsSectionSum(v.products))}</td>
																					</tr>
																					{v.products.map((v,i) => <tr key={i}>
																						<td>{v.name}</td>
																						<td className="prices">{v.count}</td>
																						<td className="prices-mid">{Utils.numFix(v.price)}</td>
																					</tr>)}
																				</React.Fragment>)}
																				<tr className="info-row">
																					<td colSpan="2">Всего</td>
																					<td className="prices">{productsTotalSum(data)}</td>
																				</tr>
																			</tbody>
																		</table>
																	: null}
																{target === 'time' ?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Статус</th>
																					<th className="datetime">Среднее время</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <tr key={i}>
																					<td className="orderstatus">
																						<div className="orderstatus-point" style={{backgroundColor:v.color?.background}}></div>
																						<div style={v.code==='finish'?{fontWeight:'bold'}:null}>{v.name}</div>
																					</td>
																					<td className="datetime" style={v.code==='finish'?{fontWeight:'bold'}:null}>{timeGet(v.time)}</td>
																				</tr>)}
																			</tbody>
																		</table>
																	: null}
																{target === 'purchases' ?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Наименование</th>
																					<th className="amount">Ед.</th>
																					<th className="amount">Накладная</th>
																					<th className="datetime">Дата</th>
																					<th>Поставщик</th>
																					<th className="prices">Кол-во</th>
																					<th className="prices-mid">Цена</th>
																					<th className="prices-mid">Сумма</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <React.Fragment key={i}>
																					{v.items.map((p,i) => <tr key={i}>
																						<td>{p.name}</td>
																						<td className="amount">{measureTypeName[p.measure]}</td>
																						<td className="amount">{v.number}</td>
																						<td className="datetime">{Moment(v.date).format('DD.MM.YYYY HH:mm')}</td>
																						<td>{v.supplier.name}</td>
																						<td className="prices">{p.quantity}</td>
																						<td className="prices-mid">{Utils.numFix(p.price)}</td>
																						<td className="prices-mid">{Utils.numFix(p.total)}</td>
																					</tr>)}
																				</React.Fragment>)}
																				{productId ?
																						<tr className="info-row">
																							<td colSpan="5">Всего</td>
																							<td className="prices">{invoicesCount(data)}</td>
																							<td colSpan="2" className="prices">{invoicesProductSum(data)}</td>
																						</tr>
																					:
																						<tr className="info-row">
																							<td colSpan="7">Всего</td>
																							<td className="prices" colSpan="2">{invoicesSum(data)}</td>
																						</tr>
																				}
																			</tbody>
																		</table>
																	: null}
																{target === 'raw' ?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Товар</th>
																					<th className="status status-small">Ед.</th>
																					<th className="amount">Поступления</th>
																					<th className="amount">Выпуск</th>
																					<th className="amount">Расход</th>
																					<th className="amount">Списание</th>
																				</tr>
																			</thead>
																			<tbody>
																				{Object.entries(data).map(([k,v],i) => <React.Fragment key={i}>
																					<tr className="section"><td colSpan="6">{v.title}</td></tr>
																					{v.data.sf !== undefined ?
																							v.data.sf.items.map((v,i) => rowGet(v, i))
																						:
																							Object.entries(v.data).map(([k2,v2],i) => <React.Fragment key={i}>
																								<tr className="section section-lite"><td colSpan="6"><b>{v2.name}</b></td></tr>
																								{v2.items.map((v,i) => rowGet(v, i))}
																							</React.Fragment>)}
																				</React.Fragment>)}
																			</tbody>
																		</table>
																	: null}
																{target === 'profit' ?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Статья</th>
																					<th className="prices-wide">Сумма</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <React.Fragment key={i}>
																					<tr key={i} className={v.istitle?'section section-lite':(v.istotal?'section':'')}>
																						<td>{v.title}</td>
																						<td className="prices-wide">{Utils.numFix(v.value)}</td>
																					</tr>
																					{v.istitle ? 
																							<tr className="empty-row">
																								<td colSpan="2"></td>
																							</tr>
																						: null}
																				</React.Fragment>)}
																			</tbody>

																		</table>
																	: null}
																{target === 'costprice' ?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Наименование</th>
																					<th className="amount">Ед.</th>
																					<th className="prices-mid">Цена</th>
																					<th className="prices-mid">Себ.</th>
																					<th className="prices-mid">Маржа, ₽</th>
																					<th className="prices-mid">Маржа, %</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <React.Fragment key={i}>
																					<tr className="section">
																						<td colSpan="6">{v.title}</td>
																					</tr>
																					{v.data.map((p,i) => <React.Fragment key={i}>
																						<tr>
																							<td>{p.name}</td>
																							<td className="amount">{p.measure} {measureTypeSmallName[p.measureType]}</td>
																							<td className="prices-mid">{Utils.numFix(p.price)}</td>
																							<td className="prices-mid">{Utils.numFix(p.costprice)}</td>
																							<td className="prices-mid"><b>{Utils.numFix(p.margin)}</b></td>
																							<td className="prices-mid">{Utils.numFix(p.marginPercent)}</td>
																						</tr>
																						{isComponentShow && p.components.length ?
																								<tr>
																									<td colSpan="6">
																										<b>Состав</b>
																										<table className="inner-table">
																											<thead>
																												<tr>
																													<th>Наименование</th>
																													<th className="amount">Ед.</th>
																													<th className="prices">Кол-во</th>
																													<th className="prices-mid">Стоимость</th>
																												</tr>
																											</thead>
																											<tbody>
																												{p.components.map((c,i) => <tr key={i}>
																													<td>{c.name}</td>
																													<td className="amount">{v.measure === measureType.PIECES ? 'шт' : 'г'}</td>
																													<td className="prices">{c.brutto}</td>
																													<td className="prices-mid">{Utils.numFix(c.total)}</td>
																												</tr>)}
																											</tbody>
																										</table>
																									</td>
																								</tr>
																							: null}
																					</React.Fragment>)}
																				</React.Fragment>)}
																			</tbody>
																		</table>
																	: null}
																{target === 'abc' ?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Наименование</th>
																					<th className="category">Категория</th>
																					<th className="quantity">Ед.</th>
																					<th className="cellABC">Прибыль</th>
																					<th className="cellABC">Выручка</th>
																					<th className="cellABC">Кол-во</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <tr key={i}>
																					<td>{v.name}</td>
																					<td className="category">{v.category.name}</td>
																					<td className="quantity">{v.weight} {measureTypeSmallName[v.measureType]}</td>
																					<td className={`cellABC cell${v.profit.type}`}>{isShowGroup ? <div>{v.profit.type}</div> : Utils.numFix(v.profit.value)}</td>
																					<td className={`cellABC cell${v.revenue.type}`}>{isShowGroup ? <div>{v.revenue.type}</div> : Utils.numFix(v.revenue.value)}</td>
																					<td className={`cellABC cell${v.quantity.type}`}>{isShowGroup ? <div>{v.quantity.type}</div> : Utils.numFix(v.quantity.value)}</td>
																				</tr>)}
																			</tbody>
																		</table>
																	: null}
																{target !== 'time' && target !== 'products' && target !== 'profit' && target !== 'costprice' && target !== 'purchases' && target !== 'raw' && target !== 'abc' && target !== 'clientsnew' && target !== 'clients'?
																		<table className="items items-no-control">
																			<thead>
																				<tr>
																					<th>Наименование</th>
																					<th className="prices">Заказы</th>
																					<th className="prices-wide">Средний чек</th>
																					<th className="prices-wide">Сумма</th>
																				</tr>
																			</thead>
																			<tbody>
																				{data.map((v,i) => <tr key={i}>
																					<td>{v.title||'Не указано'}</td>
																					<td className="prices">{Utils.numFix(v.count)}</td>
																					<td className="prices-wide">{Utils.numFix(v.median)}</td>
																					<td className="prices-wide">{Utils.numFix(v.sum)}</td>
																				</tr>)}
																				{target !== 'users' || (target === 'users' && user) ?
																						<tr className="info-row">
																							<td>Всего</td>
																							<td className="prices">{totalCountGet()}</td>
																							<td className="prices-wide">{totalMedianGet()}</td>
																							<td className="prices-wide">{totalSumGet()}</td>
																						</tr>
																					: null}
																			</tbody>
																		</table>
																	: null}
															</>
														:
															<div className="empty-inline">В указанном периоде нет заказов</div>
													)
											)
										}
									</div>
								</div>
							</>}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalOrders>
				<div className="modal modal-orders">
					<div className="header">
						<h4>История заказов</h4>
						<img src={imgClose} alt="" onClick={modalOrdersClose} className="btn-close" />
					</div>
					<div className="container-inner">
						{client?.orders.length > 0 ?
								client.orders.map((v,i) => <OrderInfo key={i} order={v} />)
							:
								<div className="orders-empty">
									Заказы не найдены
								</div>
						}
					</div>
				</div>
			</ModalOrders>
		</>
	);
};

export default ReportsScreen;