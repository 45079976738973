import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import Moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import Layout from '../../../Components/Layout';

// models
import {Statistics} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Sidemenus from '../../../Globals/Sidemenus';

// styles
import './styles.css';

// images
import imgClients from './Images/users-btn-blue.svg';
import imgOrders from './Images/orders-btn-yellow.svg';
import imgRevenue from './Images/money-btn.svg';
import imgCheck from './Images/check-btn-red.svg';
import imgUp from './Images/trand-up.svg';
import imgDown from './Images/trand-down.svg';
import imgEqual from './Images/equal.svg';

// init
Chart.register(CategoryScale);


// start
const StatisticsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [tab, setTab] = useState(0);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await statisticsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const statisticsGet = async () => {
		const data = await Statistics.get.actual().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (data === undefined) return;
		setData(data);
	};
	const dynamicGet = (type) => {
		let v = 0;
		switch (type) {
			case 'clients':
				v = data.clientsGrowth.percent.yesterday||0;
				break;
			case 'orders':
				v = data.ordersGrowth.percent.yesterday||0;
				break;
			case 'revenue':
				v = data.revenueGrowth.percent.lastmonth||0;
				break;
		}
		const ispositive = v > 0;
		const isequal = v === 0;
		const value = parseInt(v);
		return <>
				<img src={isequal ? imgEqual : (ispositive ? imgUp : imgDown)} alt="" />
				<span className={`${isequal?'':(ispositive?'up':'down')}`}>{value}%</span>
			</>;
	}
	const weekDays = () => {
		const days = [], weekdays = [];
		for (let i = 6; i >= 0; i--) {
			const d = Moment().subtract(i, 'days');
			weekdays.push(d.format('ddd'));
			days.push(d.format('DD.MM'));
		}
		return days;
	}
	const chartOptions = {
		plugins: {
			legend: {
				display: false
			}
		},
		scales: {
			y: {
				beginAtZero: false
			},
			x: {
				grid: {
					display: false
				}
			}
		}
	};
	const tabSet = (tab) => setTab(tab);
	const tabsBlock = <div className="categories-tabs">
		<div className="tabs">
			<div className={`tab${tab===0?' active':''}`} onClick={() => tabSet(0)}>Разделы</div>
			<div className={`tab${tab===1?' active':''}`} onClick={() => tabSet(1)}>Отчеты</div>
		</div>
	</div>;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Статистика"
							type="statistics"
							contentTabs={tab === 0 ? tabsBlock : null}
							contentSideMenu={tab === 1 ?
								<>
									<div className="categories-list-container">
										{tabsBlock}
										<div className="container-inner">
											<div className="list">
												{Sidemenus.reports.map((v,i) => <div key={i} className="category-section" onClick={() => {}}>
													<div className="category-section-title">{v.name}</div>
													{v.items.map((v,i) => <Link key={i} to={v.link} className="category">{v.name}</Link>)}
												</div>)}
											</div>
										</div>
									</div>
								</>
							: null}
							contentRows={<>
								<div className="statistic-container">
									<div className="main">
										{data ?
												<>
													<div className="charts-small-blocks stat-oneline">
														<div className="chart">
															<div className="stat-oneline-wide stat-oneline-up">
																<div className="title">Клиенты</div>
																<img src={imgClients} alt="" />
															</div>
															<h3>{data.clients.all}</h3>
															<div className="trends stat-oneline stat-oneline-bottom">
																{dynamicGet('clients')}
																<span>со вчера</span>
															</div>
														</div>
														<div className="chart">
															<div className="stat-oneline-wide stat-oneline-up">
																<div className="title">Заказы</div>
																<img src={imgOrders} alt="" />
															</div>
															<h3>{data.orders.all}</h3>
															<div className="trends stat-oneline stat-oneline-bottom">
																{dynamicGet('orders')}
																<span>со вчера</span>
															</div>
														</div>
														<div className="chart">
															<div className="stat-oneline-wide stat-oneline-up">
																<div className="title">Выручка</div>
																<img src={imgRevenue} alt="" />
															</div>
															<h3>{Utils.moneyFormat(data.revenue.all, false)} ₽</h3>
															<div className="trends stat-oneline stat-oneline-bottom">
																{dynamicGet('revenue')}
																<span>с прошлого месяца</span>
															</div>
														</div>
														<div className="chart">
															<div className="stat-oneline-wide stat-oneline-up">
																<div className="title">Средний чек</div>
																<img src={imgCheck} alt="" />
															</div>
															<h3>{Utils.moneyFormat(data.medianCheck.amount.all, false)} ₽</h3>
															<div className="trends stat-oneline stat-oneline-bottom">
																{dynamicGet('median')}
																<span>с прошлой недели</span>
															</div>
														</div>
													</div>
													<div className="charts-big-blocks stat-oneline">
														<div className="chart">
															<div className="stat-oneline-wide stat-oneline-up">
																<div className="title">Выручка</div>
															</div>
															<Bar
																width={'100%'}
																height={40}
																data={{
																	labels: weekDays(),
																	datasets: [
																		{
																			data: data.revenue.lastweekbydays,
																			backgroundColor:'#2891fb'
																		}
																	]
																}}
																options={{
																	borderRadius:4,
																	barPercentage:.7,
																	hoverBackgroundColor:'#89baeb',
																	borderSkipped:'bottom',
																	...chartOptions
																}}
															/>
														</div>
														<div className="chart">
															<div className="stat-oneline-wide stat-oneline-up">
																<div className="title">Процент заказов на доставку</div>
															</div>
															<Line
																width={'100%'}
																height={40}
																data={{
																	labels: weekDays(),
																	datasets: [
																		{
																			data: data.delivery.percent.lastweekbydays
																		}
																	]
																}}
																options={{
																	pointBackgroundColor:'#2792fb',
																	pointBorderColor:'#ffffff',
																	pointBorderWidth:2,
																	pointRadius:4,
																	borderColor:'#2792fb80',
																	tension:.2,
																	...chartOptions
																}}
															/>
														</div>
													</div>
													<div className="charts-big-blocks stat-oneline">
														<div className="chart">
															<div className="stat-oneline-wide stat-oneline-up">
																<div className="title">Средний чек</div>
															</div>
															<Bar
																width={'100%'}
																height={40}
																data={{
																	labels: weekDays(),
																	datasets: [
																		{
																			data: data.medianCheck.amount.lastweekbydays,
																			backgroundColor:'#2891fb'
																		}
																	]
																}}
																options={{
																	borderRadius:4,
																	barPercentage:.7,
																	hoverBackgroundColor:'#89baeb',
																	borderSkipped:'bottom',
																	...chartOptions
																}}
															/>
														</div>
														<div className="chart">
															<div className="stat-oneline-wide stat-oneline-up">
																<div className="title">Процент отказов</div>
															</div>
															<Line
																width={'100%'}
																height={40}
																data={{
																	labels: weekDays(),
																	datasets: [
																		{
																			data: data.refusals.percent.lastweekbydays
																		}
																	]
																}}
																options={{
																	pointBackgroundColor:'#2792fb',
																	pointBorderColor:'#ffffff',
																	pointBorderWidth:2,
																	pointRadius:4,
																	borderColor:'#2792fb80',
																	tension:.2,
																	...chartOptions
																}}
															/>
														</div>
													</div>
												</>
											:
												<div className="empty">
													<Empty image={'statistic'} text={'После первого заказа здесь появится статистика'} />
												</div>
										}
									</div>
								</div>
							</>}
						/>
					)
				}
			</Template>
		</>
	);
};

export default StatisticsScreen;