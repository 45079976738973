const books = [
	{name:'Товары',link:'/books/products'},
	{name:'Категории товаров',link:'/books/categories'},
	{name:'Опции',link:'/books/options'},
	{name:'Группы опций',link:'/books/optiongroups'},
	{name:'Компоненты (сырье)',link:'/books/components'},
	{name:'Полуфабрикаты',link:'/books/semifinishes'},
	{name:'Клиенты',link:'/books/clients'},
	{name:'Поставщики',link:'/books/suppliers'},
	{name:'Склады',link:'/books/storages'},
	{name:'Цехи',link:'/books/workshops'},
	{name:'Причины списания',link:'/books/reasons'},
	{name:'Категории расходов',link:'/books/expensecategories'},
	{name:'Источники заказов',link:'/books/sources'},
	{name:'Метки заказа',link:'/books/labels'},
	{name:'Ярлыки товаров',link:'/books/tags'},
	{name:'Статусы заказа',link:'/books/statuses'},
	{name:'Сотрудники',link:'/books/users'},
	{name:'Роли',link:'/books/roles'}
];

const marketing = [
	{name:'Акции',link:'/marketing/actions'},
	{name:'Программа лояльности',link:'/marketing/loyalty'},
	{name:'Бонусы',link:'/marketing/bonuses'},
	{name:'Реферальная программа',link:'/marketing/referrals'},
	{name:'Промокоды',link:'/marketing/promocodes'},
	{name:'Правила корзины',link:'/marketing/basket'},
	{name:'События',link:'/marketing/events'},
	{name:'Отзывы, рейтинг',link:'/marketing/feedbacks'},
	{name:'Пуш-уведомления',link:'/marketing/pushes'},
	{name:'СМС-рассылки',link:'/marketing/sms'}
];

const orders = [
	{name:'Заказы',link:'/orders'},
	{name:'Архив заказов',link:'/orders/archive'}
];

const warehouse = [
	{name:'Накладные',link:'/warehouse/invoices'},
	{name:'Списания',link:'/warehouse/writeoffs'},
	{name:'Выпуск продукции',link:'/warehouse/releases'},
	{name:'Перемещение',link:'/warehouse/movements'},
	{name:'Остатки',link:'/warehouse/balances'},
	{name:'Инвентаризации',link:'/warehouse/inventories'}
];

const money = [
	{name:'Касса',link:'/money/cashbox'},
	{name:'Прочие расходы',link:'/money/expenses'},
	{name:'Заработная плата',link:'/money/salary'},
	{name:'Транзакции',link:'/money/transactions'}
];

const statistics = [
	{name:'Сводка',link:'/statistics'},
	{name:'Отчеты',link:'/statistics/reports'},
	{name:'Табель',link:'/statistics/worktime'},
	{name:'Смены',link:'/statistics/dayshifts'}
];

const account = [
	{name:'Профиль',link:'/account'},
	{name:'Филиалы',link:'/account/points'},
	{name:'Зоны доставки',link:'/account/deliveryareas'},
	{name:'Точки самовывоза',link:'/account/areas'}
];

const settings = [
	{link:'/settings',name:'Общие'},
	{link:'/settings/site',name:'Сайт'},
	{link:'/settings/apps',name:'Мобильные приложения'},
	{link:'/settings/payment',name:'Оплата'},
	{link:'/settings/integrations',name:'Интеграции'},
	{link:'/settings/services',name:'Сервисы'},
	{link:'/settings/messages',name:'Шаблоны сообщений'},
	{link:'/settings/links',name:'Ссылки'},
	{link:'/settings/documents',name:'Документы'}
];

const support = [
	{name:'Обращения пользователей',link:'/support/feedback'},
	{name:'Служба поддержки',link:'/support'}
];

const reports = [
	{name:'Продажи',items:[
		{name:'Выручка',link:'/statistics/reports',tophide:true},
		{name:'Отказы',link:'/statistics/reports/rejects',tophide:true},
		{name:'Доставка',link:'/statistics/reports/delivery',tophide:true},
		{name:'Скидки и наценки',link:'/statistics/reports/discount',tophide:true},
		{name:'Сотрудники',link:'/statistics/reports/users',tophide:true},
		{name:'Источники',link:'/statistics/reports/sources',tophide:true},
		{name:'Каналы продаж',link:'/statistics/reports/channels',tophide:true},
		{name:'Время исполнения',link:'/statistics/reports/time',tophide:true},
		{name:'Продажи по часам',link:'/statistics/reports/hours',tophide:true},
		{name:'Прибыль и убытки',link:'/statistics/reports/profit',tophide:true}
	]},
	{name:'Клиенты',items:[
		{name:'Клиенты',link:'/statistics/reports/clients',tophide:true},
		{name:'Заказы клиентов',link:'/statistics/reports/clientsorders',tophide:true},
		{name:'Регистрации',link:'/statistics/reports/clientsnew',tophide:true}
	]},
	{name:'Склад',items:[
		{name:'Товары',link:'/statistics/reports/products',tophide:true},
		{name:'Себестоимость',link:'/statistics/reports/costprice',tophide:true},
		{name:'Движение сырья',link:'/statistics/reports/raw',tophide:true},
		{name:'История закупок',link:'/statistics/reports/purchases',tophide:true},
		{name:'ABC анализ',link:'/statistics/reports/abc',tophide:true}
	]}
];


const Sidemenus = {
	books,
	marketing,
	orders,
	warehouse,
	money,
	statistics,
	account,
	settings,
	support,
	reports
};
export default Sidemenus;