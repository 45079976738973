import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';
import Accordion from '../../../Components/Accordion';

// models
import {Baskets, DeliveryAreas, Loyalities, Points, Products, Promocodes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { commonStatus, commonStatusName, deliveryTypeName, measureTypeName, paymentTypeName, promoCodeTypeName, sourceTypeName } from '../../../Globals/Constants';
import { basketDiscountTypeName, basketRuleActions, basketRules, compareLiteTypeName, compareTypeName, concatenateType, directionTypeName } from '../../../Globals/ConstantRulesActions';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';

// start
const BasketScreen = () => {
	const [ModalCnditionsActions, modalConditionsActionsOpen, modalConditionsActionsClose] = useModal('root');
	const [ModalChildren, modalChildrenOpen, modalChildrenClose] = useModal('root');
	const [ModalProducts, modalProductsOpen, modalProductsClose] = useModal('root');
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [points, setPoints] = useState([]);
	const [baskets, setBaskets] = useState(null);
	const [basketsAll, setBasketsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:dateStart, setValue:setDateStart, bind:bindDateStart} = useInput('');
	const {value:dateEnd, setValue:setDateEnd, bind:bindDateEnd} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const [isStop, setIsStop] = useState(false);
	const [forClientOnly, setForClientOnly] = useState(false);
	const [deliveryAreas, setDeliveryAreas] = useState([]);
	const [promocodes, setPromocodes] = useState([]);
	const [loyalities, setLoyalities] = useState([]);
	const [products, setProducts] = useState([]);
	const [productsAll, setProductsAll] = useState([]);
	const [basketPoints, setBasketPoints] = useState([]);
	const [search, setSearch] = useState(null);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [productsSelected, setProductsSelected] = useState([]);
	const [modalType, setModalType] = useState(null);
	const [conditions, setConditions] = useState([]);
	const [actions, setActions] = useState([]);
	const [condition, setCondition] = useState(null);
	const [conditionIndex, setConditionIndex] = useState(null);
	const [temp, setTemp] = useState(null);
	const [childrenItems, setChildrenItems] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await deliveryAreasGet();
			await promocodesGet();
			await loyalitiesGet();
			await productsGet();
			await basketsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const basketsGet = async () => {
		const baskets = await Baskets.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (baskets === undefined) return;
		setBaskets(baskets);
		setBasketsAll(baskets);
	};
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const deliveryAreasGet = async () => {
		const areas = await DeliveryAreas.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setDeliveryAreas(areas);
	};
	const promocodesGet = async () => {
		const promocodes = await Promocodes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promocodes === undefined) return;
		setPromocodes(promocodes);
	};
	const loyalitiesGet = async () => {
		const loyalities = await Loyalities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (loyalities === undefined) return;
		setLoyalities(loyalities);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
	};
	const basketAdd = () => show();
	const basketEdit = (basket) => {
		basket = basket || baskets.find(f => f._id === id);
		show(basket);
	};
	const basketShow = (basket) => show(basket);
	const show = (basket) => {
		setId(basket?._id||null);
		setName(basket?.name||'');
		setDescription(basket?.description||'');
		setConditions(basket?.conditions||[]);
		setActions(basket?.actions||[]);
		setDateStart(Utils.dateNormalize(basket?.properties.period, 'start'));
		setDateEnd(Utils.dateNormalize(basket?.properties.period, 'end'));
		setSortOrder(basket?.options.sortOrder||'');
		setIsStop(basket?.options.isStop||false);
		setForClientOnly(basket?.options.forClientOnly||false);
		setBasketPoints(basket ? basket.points : points.map(v => ({id:v._id,name:v.name})));
		setStatus(basket?basket.status:'');
	};
	const cancel = () => {
		show(null);
		modalChangeStatusClose();
	}
	const save = async () => {
		const data = {
			name:name,
			description:description,
			points:basketPoints,
			conditions:conditions,
			actions:actions,
			dateStart:dateStart,
			dateEnd:dateEnd,
			sortOrder:sortOrder,
			status:status
		};
		if (isStop) data.isStop = isStop;
		if (forClientOnly) data.forClientOnly = forClientOnly;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название правила');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		const res = id ?
				await Baskets.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				})
			:
				await Baskets.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				});
		if (res) {
			successShow(res.message);
			basketsGet();
			cancel();
			return true;
		}
	}
	const searchBasket = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setBaskets(basketsAll);
		else setBaskets(basketsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const basketChangeStatusOn = (id) => basketChangeStatus(id);
	const basketChangeStatusModal = () => basketChangeStatus(id);
	const basketChangeStatus = (id) => {
		const basket = baskets.find(f => f._id === id);
		const status = basket.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		Baskets.status(id, {status}).then((res) => {
			successShow(res.message);
			basketsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (id) => {
		setId(id);
		setName(baskets.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const basketDelete = (id) => {
		Baskets.remove(id).then((res) => {
			successShow(res.message);
			basketsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const productsShow = () => modalProductsOpen();
	const productSelect = (id) => {
		if (productsSelected.includes(id)) setProductsSelected(productsSelected.filter(f => f !== id));
		else setProductsSelected([...productsSelected, id]);
	};
	const productRemove = (i) => {
		const products = productsSelected;
		products.splice(i,1);
		setProductsSelected([...products]);
	};
	const searchProduct = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const productAdd = () => {
		modalProductsClose();
		setProducts(productsAll);
	};
	const conditionsShow = (type) => {
		setModalType(type);
		modalConditionsActionsOpen();
	}
	const conditionsHide = () => {
		conditionHide();
		childrenHide();
		modalConditionsActionsClose();
	}
	const conditionHide = () => {
		setCondition(null);
		setTemp(null);
	};
	const childrenHide = () => {
		setChildrenItems(null);
		setProductsSelected([]);
		modalChildrenClose();
	}
	const conditionSelect = (con, i) => {
		const values = i === null ? null : conditions[i];
		setTemp(conditionPrepare(con, values));
		setCondition(con);
		setConditionIndex(i);
	};
	const conditionPrepare = (con, values) => {
		const c = {
			type:con.type,
		};
		if (con.compare !== undefined) c.compare = values ? values.compare : con.compare;
		if (con.comparelite !== undefined) c.comparelite = values ? values.comparelite : con.comparelite;
		if (con.concatenate !== undefined) c.concatenate = values ? values.concatenate : con.concatenate;
		if (con.value !== undefined) c.value = values ? values.value : con.value;
		if (con.direction !== undefined) c.direction = values ? values.direction : con.direction;
		if (con.comment !== undefined) c.comment = values ? values.comment : con.comment;
		if (con.products !== undefined) c.products = values ? values.products : con.products;
		if (con.children !== undefined) {
			if (values && values.children) {
				c.children = values.children.map((v,i) => conditionPrepare(con.children[i], v));
			} else c.children = [];
		}
		return c;
	};
	const conditionsApply = () => {
		const c = (modalType === 'conditions' ? conditions : actions) || [];
		const condition = temp;
		if (temp.children) {
			const children = temp.children;
			children.forEach((v,i) => {
				if (v.type === 'product_in_basket') v.value = productsSelected;
			});
		}
		const products = ['product_free','products_gift','products_recommend','product_required','product_required_free','products_discount','products_discount_price','products_cheapest_free','products_cheapest_discount','products_expensive_discount']
		if (products.includes(temp.type)) temp.products = productsSelected;
		if (conditionIndex !== null) c[conditionIndex] = condition;
		else c.push(condition);
		if (modalType === 'conditions') setConditions(c);
		else setActions(c);
		conditionHide();
	};
	const valueRemove = (i) => {
		const value = temp.value;
		value.splice(i,1);
		setTemp({...temp,value});
	};
	const valueNameGet = (type, value, enums) => {
		switch (type) {
			case 'delivery_area':
			case 'promo_code':
			case 'client_status':
			case 'product_in_basket':
			case 'product_free':
				return enums.find(f => f._id === value)?.name;
		}
		return enums[value];
	};
	const dataViewRender = (type) => {
		const out = [];
		const source = [];
		const data = type === 'conditions' ? conditions : actions;
		const dataFull = (type === 'conditions' ? basketRules : basketRuleActions)
		dataFull.forEach((v) => {
			v.items.forEach((v) => {
				const s = {...v};
				if (s.children) source.push(...s.children);
				delete s.children;
				source.push(s);
			});
		});
		const templateDataGet = (type) => {
			let d = null;
			dataFull.forEach((v) => {
				v.items.forEach((v) => {
					if (v.type === type) d = v;
				});
			});
			return d;
		}
		const renderItem = (data, type, idx, ischild) => {
			const s = source.find(f => f.type === type);
			if (!s) return null;
			const title = s.description||s.title;
			const postfix = s.postfix;
			const prefix = s.prefix;
			let out = null;
			switch (type) {
				case 'product_count':
				case 'products_sum':
				case 'date_time':
				case 'preorder_date_time':
					out = <>
						<div className="condition-title">{title}</div>
						{concatenateSwitcherView(data)}
						{type === 'product_count' || type === 'products_sum' ?
							<>
								{compareSwitcherView(data)}
								<code>{data.value}</code>
							</>
						: null}
						
						{data.children ?
								<>
									{data.children.length ?
											data.children.map((v,i) => <div key={i}>
												{renderItem(v, v.type, i, true)}
													{data.children.length > 1 && i !== data.children.length-1 ?
														<div className={`condition-concatenate-separator${data.concatenate===concatenateType.AND?' condition-concatenate-separator-and':''}`}>{data.concatenate === concatenateType.AND ? 'И' : 'ИЛИ'}</div>
													: null}
											</div>)
										: null}
								</>
							: null
						}
					</>;
					break;
				case 'day_of_week':
				case 'preorder_day_of_week':
				case 'order_source':
				case 'order_delivery':
				case 'payment_type':
				case 'promo_code_type':
				case 'delivery_area':
				case 'promo_code':
				case 'client_status':
					let enums;
					switch (type) {
						case 'order_source':
							enums = sourceTypeName;
							break;
						case 'day_of_week':
						case 'preorder_day_of_week':
							enums = Utils.weekDayName.full;
							break;
						case 'order_delivery':
							enums = deliveryTypeName;
							break;
						case 'payment_type':
							enums = paymentTypeName;
							break;
						case 'promo_code_type':
							enums = promoCodeTypeName;
							break;
						case 'delivery_area':
							enums = deliveryAreas;
							break;
						case 'promo_code':
							enums = promocodes;
							break;
						case 'client_status':
							enums = loyalities;
							break;
					}
					if (!enums) break;
					out = <>
						<div className="condition-title">{title}</div>
						{compareSwitcherView(data)}
						<div className="condition-value">
							{data.value.map((v,i) => <span key={i} className="condition-value-item">
								{valueNameGet(type, v, enums)}
							</span>)}
						</div>
					</>;
					break;
				case 'order_date':
				case 'preorder_date':
				case 'order_time':
				case 'preorder_time':
				case 'birthday_date':
					out = <div className="condition-row-inner">
						<div className="condition-title">{title}</div>
						{compareSwitcherView(data)}
						<span className="condition-value-item condition-value-item-datetime">
							<code>{data.value}</code>
						</span>
					</div>;
					break;
				case 'product_in_basket':
					out = <>
						<div className="condition-title">{title}</div>
						{compareSwitcherView(data)}
						<div className="condition-value">
							{data.value.map((v,i) => <span key={i} className="condition-value-item">
								{valueNameGet(type, v, products)}
							</span>)}
						</div>
					</>;
					break;
				case 'product_free':
				case 'products_gift':
				case 'products_recommend':
				case 'product_required':
				case 'product_required_free':
				case 'products_discount':
				case 'products_discount_price':
				case 'products_cheapest_free':
				case 'products_cheapest_discount':
				case 'products_expensive_discount':
					out = <>
						<div className="condition-row-inner">
							<div className="condition-title">{title}</div>
							{type === 'products_recommend' ? null :
								<span className="condition-value-item condition-value-item-datetime">
									<code>{data.value}</code>
									{type === 'products_discount' || type === 'products_cheapest_discount' || type === 'products_expensive_discount' ? discountsTypeSwitcherView(data) : null}
									{postfix ? <span className="condition-postfix">{postfix}</span> : null}
								</span>
							}
						</div>
						{data.products !== undefined ?
								<div className="condition-value">
									{productsSelected.map((v,i) => <span key={i} className="condition-value-item">
										{valueNameGet(type, v, products)}
									</span>)}
								</div>
							: null}
						{data.comment !== undefined ?
								<div className="condition-value">
									<pre>{data.comment}</pre>
								</div>
							: null}
					</>;
					break;
				case 'product_price_full':
				case 'product_price':
				case 'product_count_basket':
				case 'order_price':
				case 'preorder_days':
				case 'preorder_hours':
				case 'preorder_minutes':
				case 'age':
				case 'client_bonuses':
					out = <div className="condition-row-inner">
						<div className="condition-title">{title}</div>
						{compareSwitcherView(data)}
						<code>{data.value}</code>
						{postfix ? <span className="condition-postfix">{postfix}</span> : null}
					</div>;
					break;
				case 'client_orders':
				case 'client_orders_sum':
					out = <div className="condition-row-inner">
						<div className="condition-title">{title}</div>
						{monthsSwitcherView(data, idx)}
						{compareSwitcherView(data)}
						<input type="text" placeholder="0" className="inline" value={data.value} onChange={(e) => inputHandler(e, idx, ischild)} maxLength={5} />
						{postfix ? <span className="condition-postfix">{postfix}</span> : null}
					</div>;
					break;
				case 'birthday_days':
					out = <>
						<div className="condition-title">{title}</div>
						{afterBeforeSwitcherView(data, idx)}
						{prefix ? <span className="condition-prefix">{prefix}</span> : null}
						{compareSwitcherView(data)}
						<code>{data.value}</code>
						{postfix ? <span className="condition-postfix">{postfix}</span> : null}
					</>;
					break;
				case 'is_first_order':
				case 'is_birthday':
					out = <>
						<div className="condition-title">{title}</div>
						{compareSwitcherView(data)}
					</>;
					break;
				case 'promo_code_disable':
				case 'discounts_disable':
				case 'gifts_disable':
				case 'gifts_sum_disable':
				case 'free_delivery_disable':
				case 'payment_bonus_disable':
				case 'loyalty_disable':
					out = <>
						<div className="condition-title">{title}</div>
						{data.comment !== undefined ?
								<div className="condition-value">
									<pre>{data.comment}</pre>
								</div>
							: null}
					</>;
					break;
				default: return null;
			};
			return out ?
					<div key={idx} className="condition-row" onClick={() => ischild ? null : conditionSelect(templateDataGet(type), idx)}>
						{out}
						{ischild ? null : <div className="condition-remove" onClick={() => conditionRemove(idx)}>×</div>}
					</div>
				: null;
		};
		data.forEach((v,i) => {
			const item = renderItem(v, v.type, i, false);
			if (item) out.push(item);
		});
		return out;
	};
	const concatenateSwitcherView = (v) => <span className="condition-concatenate">{v.concatenate === concatenateType.AND ? 'всем условиям' : 'любому из условий'}</span>;
	const compareSwitcherView = (v) => {
		if (v.type === 'is_first_order' || v.type === 'is_birthday') return <span className="condition-compare">{v.compare === 0 ? 'Нет' : 'Да'}</span>;
		return <span className="condition-compare">
			{v.compare !== undefined ? compareTypeName[v.compare] : null}
			{v.comparelite !== undefined ? compareLiteTypeName[v.comparelite] : null}
		</span>;
	};
	const discountsTypeSwitcherView = (v) => <span className="condition-compare">{basketDiscountTypeName[v.direction]}</span>;
	const afterBeforeSwitcherView = (v) => <span className="condition-compare">{directionTypeName[v.direction]}</span>;
	const monthsSwitcherView = (v) => <span className="condition-compare">{Orfo.counters.months(v)}</span>;
	const dataFormRender = (con, idx, ischild) => {
		const out = [];
		const type = con.type;
		const children = ischild ? condition.children.find(f => f.type === type) : null;
		const title = ischild ? children.description||children.title : con.description||con.title;
		const postfix = ischild ? children.postfix : con.postfix;
		const prefix = ischild ? children.prefix : con.prefix;
		const placeholder = ischild ? children.placeholder : con.placeholder;
		const data = ischild ? temp.children[idx] : temp;
		switch (type) {
			case 'product_count':
			case 'products_sum':
			case 'date_time':
			case 'preorder_date_time':
				out.push(<>
					<div className="condition-title">{title}</div>
					{concatenateSwitcher(data)}
					{type === 'product_count' || type === 'products_sum' ?
							<>
								{compareSwitcher(data, idx, ischild)}
								<input type="text" placeholder="0" className="inline" value={data.value} onChange={(e) => inputHandler(e, idx, ischild)} maxLength={5} />
							</>
						: null}
					{data.children ?
							<>
								{data.children.length ?
										data.children.map((v,i) => <div key={i}>
											{dataFormRender(v, i, true)}
												{data.children.length > 1 && i !== data.children.length-1 ?
													<div className={`condition-concatenate-separator${data.concatenate===concatenateType.AND?' condition-concatenate-separator-and':''}`}>{data.concatenate === concatenateType.AND ? 'И' : 'ИЛИ'}</div>
												: null}
										</div>)
									: null}
								<div className="button-row">
									<div className="button-add" onClick={childrenShow}>Добавить условие</div>
								</div>
							</>
						: null
					}
				</>);
				break;
			case 'day_of_week':
			case 'preorder_day_of_week':
			case 'order_source':
			case 'order_delivery':
			case 'payment_type':
			case 'promo_code_type':
			case 'delivery_area':
			case 'promo_code':
			case 'client_status':
				let enums, buttonName = 'Выбрать';
				switch (type) {
					case 'order_source':
						enums = sourceTypeName;
						buttonName = 'Выбрать источник';
						break;
					case 'day_of_week':
					case 'preorder_day_of_week':
						enums = Utils.weekDayName.full;
						buttonName = 'Выбрать день';
						break;
					case 'order_delivery':
						enums = deliveryTypeName;
						buttonName = 'Выбрать способ';
						break;
					case 'payment_type':
						enums = paymentTypeName;
						buttonName = 'Выбрать способ';
						break;
					case 'promo_code_type':
						enums = promoCodeTypeName;
						buttonName = 'Выбрать тип промокода';
						break;
					case 'delivery_area':
						enums = deliveryAreas;
						buttonName = 'Выбрать зону';
						break;
					case 'promo_code':
						enums = promocodes;
						buttonName = 'Выбрать промокод';
						break;
					case 'client_status':
						enums = loyalities;
						buttonName = 'Выбрать статус';
						break;
				}
				if (!enums) break;
				out.push(<>
					<div className="condition-title">{title}</div>
					{compareSwitcher(data, idx, ischild)}
					<div className="condition-value">
						{data.value.map((v,i) => <span key={i} className="condition-value-item">
							{valueNameGet(type, v, enums)}
							<span className="condition-value-remove" onClick={() => valueRemove(i)}>×</span>
						</span>)}
					</div>
					<div className="button-row">
						<div className="button-add" onClick={childrenShow}>{buttonName}</div>
					</div>
				</>);
				break;
			case 'order_date':
			case 'preorder_date':
			case 'order_time':
			case 'preorder_time':
			case 'birthday_date':
				const istime = type.indexOf('time') !== -1;
				out.push(<div className="condition-row-inner">
					<div className="condition-title">{title}</div>
					{compareSwitcher(data, idx, ischild)}
					<span className="condition-value-item condition-value-item-datetime">
						<input type={istime?'time':'date'} value={data.value} className={`inline ${istime?'inline-time':'inline-date'}`} onChange={(e) => inputHandler(e, idx, ischild)} />
					</span>
				</div>);
				break;
			case 'product_in_basket':
				out.push(<>
					<div className="condition-title">{title}</div>
					{compareSwitcher(data, idx, ischild)}
					<div className="condition-value">
						{productsSelected.map((v,i) => <span key={i} className="condition-value-item">
							{valueNameGet(type, v, products)}
							<span className="condition-value-remove" onClick={() => productRemove(i)}>×</span>
						</span>)}
						<span className="condition-value-item condition-value-item-button" onClick={productsShow}>Добавить позицию</span>
					</div>
				</>);
				break;
			case 'product_free':
			case 'products_gift':
			case 'products_recommend':
			case 'product_required':
			case 'product_required_free':
			case 'products_discount':
			case 'products_discount_price':
			case 'products_cheapest_free':
			case 'products_cheapest_discount':
			case 'products_expensive_discount':
				out.push(<>
					<div className="condition-row-inner">
						<div className="condition-title">{title}</div>
						{type === 'products_recommend' ? null :
							<span className="condition-value-item condition-value-item-datetime">
								<input type="text" placeholder="0" className="inline" value={data.value} onChange={(e) => inputHandler(e, idx, ischild)} maxLength={5} />
								{type === 'products_discount' || type === 'products_cheapest_discount' || type === 'products_expensive_discount' ? discountsTypeSwitcher(data, idx) : null}
								{postfix ? <span className="condition-postfix">{postfix}</span> : null}
							</span>
						}
					</div>
					{data.products !== undefined ?
							<div className="condition-value">
								{productsSelected.map((v,i) => <span key={i} className="condition-value-item">
									{valueNameGet(type, v, products)}
									<span className="condition-value-remove" onClick={() => productRemove(i)}>×</span>
								</span>)}
								<span className="condition-value-item condition-value-item-button" onClick={productsShow}>Добавить позицию</span>
							</div>
						: null}
					{data.comment !== undefined ?
							<div className="condition-value">
								<input type="text" placeholder={placeholder} className="inline inline-wide" value={data.comment} onChange={inputCommentHandler} />
							</div>
						: null}
				</>);
				break;
			case 'product_price_full':
			case 'product_price':
			case 'product_count_basket':
			case 'order_price':
			case 'preorder_days':
			case 'preorder_hours':
			case 'preorder_minutes':
			case 'age':
			case 'client_bonuses':
				out.push(<div className="condition-row-inner">
					<div className="condition-title">{title}</div>
					{compareSwitcher(data, idx, ischild)}
					<input type="text" placeholder="0" className="inline" value={data.value} onChange={(e) => inputHandler(e, idx, ischild)} maxLength={5} />
					{postfix ? <span className="condition-postfix">{postfix}</span> : null}
				</div>);
				break;
			case 'client_orders':
			case 'client_orders_sum':
				out.push(<div className="condition-row-inner">
					<div className="condition-title">{title}</div>
					{monthsSwitcher(data, idx)}
					{compareSwitcher(data, idx, ischild)}
					<input type="text" placeholder="0" className="inline" value={data.value} onChange={(e) => inputHandler(e, idx, ischild)} maxLength={5} />
					{postfix ? <span className="condition-postfix">{postfix}</span> : null}
				</div>);
				break;
			case 'birthday_days':
				out.push(<>
					<div className="condition-title">{title}</div>
					{afterBeforeSwitcher(data, idx)}
					{prefix ? <span className="condition-prefix">{prefix}</span> : null}
					{compareSwitcher(data, idx, ischild)}
					<input type="text" placeholder="0" className="inline" value={data.value} onChange={(e) => inputHandler(e, idx, ischild)} maxLength={5} />
					{postfix ? <span className="condition-postfix">{postfix}</span> : null}
				</>);
				break;
			case 'is_first_order':
			case 'is_birthday':
				out.push(<>
					<div className="condition-title">{title}</div>
					{compareSwitcher(data, idx, ischild)}
				</>);
				break;
			case 'promo_code_disable':
			case 'discounts_disable':
			case 'gifts_disable':
			case 'gifts_sum_disable':
			case 'free_delivery_disable':
			case 'payment_bonus_disable':
			case 'loyalty_disable':
				out.push(<>
					<div className="condition-title">{title}</div>
					{data.comment !== undefined ?
							<div className="condition-value">
								<input type="text" placeholder={placeholder} className="inline inline-wide" value={data.comment} onChange={inputCommentHandler} />
							</div>
						: null}
				</>);
				break;
		};
		return <div key={idx} className="condition-row">
				{out}
				{ischild ? <div className="condition-remove" onClick={() => childRemove(idx)}>×</div> : null}
			</div>;
	}
	const concatenateChange = (value) => setTemp({...temp,concatenate:value});
	const compareHandler = (e, i, ischild) => {
		if (ischild) {
			const children = temp.children;
			children[i].compare = parseInt(e.target.value);
			setTemp({...temp,children});
		}
		else setTemp({...temp,compare:parseInt(e.target.value)});
	}
	const compareLiteHandler = (e, i, ischild) => {
		if (ischild) {
			const children = temp.children;
			children[i].comparelite = parseInt(e.target.value);
			setTemp({...temp,children});
		}
		else setTemp({...temp,comparelite:parseInt(e.target.value)});
	}
	const afterBeforeHandler = (e) => setTemp({...temp,direction:parseInt(e.target.value)});
	const inputHandler = (e, i, ischild) => {
		if (ischild) {
			const children = temp.children;
			children[i].value = e.target.value;
			setTemp({...temp,children});
		}
		else setTemp({...temp,value:e.target.value});
	}
	const inputCommentHandler = (e) => setTemp({...temp,comment:e.target.value});
	const concatenateSwitcher = (v) => <span className="condition-concatenate" onClick={() => concatenateChange(v.concatenate === concatenateType.AND ? concatenateType.OR : concatenateType.AND)}>{v.concatenate === concatenateType.AND ? 'всем условиям' : 'любому из условий'}</span>;
	const compareSwitcher = (con, i, ischild) => {
		if (con.type === 'is_first_order' || con.type === 'is_birthday') {
			return <span className="condition-compare">
				<select onChange={(e) => compareHandler(e, i, ischild)} value={con.compare}>
					<option value={0}>Нет</option>
					<option value={1}>Да</option>
				</select>
			</span>;
		}
		return <span className="condition-compare">
			{con.compare !== undefined ?
				<select onChange={(e) => compareHandler(e, i, ischild)} value={con.compare}>
					{compareTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
				</select> : null}
			{con.comparelite !== undefined ?
				<select onChange={(e) => compareLiteHandler(e, i, ischild)} value={con.comparelite}>
					{compareLiteTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
				</select> : null}
		</span>;
	};
	const afterBeforeSwitcher = (v, i) => {
		return <span className="condition-compare">
			<select onChange={(e) => afterBeforeHandler(e, i)} value={v.direction}>
				{directionTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
			</select>
		</span>;
	};
	const monthsSwitcher = (v, i) => {
		return <span className="condition-compare">
			<select onChange={(e) => afterBeforeHandler(e, i)} value={v.direction}>
				{[1,2,3,4,5,6,7,8,9,10,11,12].map((v,i) => <option key={i} value={i}>{Orfo.counters.months(v)}</option>)}
			</select>
		</span>;
	};
	const discountsTypeSwitcher = (v, i) => {
		return <span className="condition-compare">
			<select onChange={(e) => afterBeforeHandler(e, i)} value={v.direction}>
				{basketDiscountTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
			</select>
		</span>;
	};
	const childrenRender = () => childrenItems?.map((v,i) => <div key={i} className="condition-list-item" onClick={() => childSelect(v)}>{v.title}</div>);
	const childrenShow = () => {
		switch (condition.type) {
			case 'day_of_week':
			case 'preorder_day_of_week':
				setChildrenItems(Utils.weekDayName.full.map((v,i) => ({title:v,value:i})));
				break;
			case 'order_source':
				setChildrenItems(sourceTypeName.map((v,i) => ({title:v,value:i})).filter(f => f.value !== 0));
				break;
			case 'order_delivery':
				setChildrenItems(deliveryTypeName.map((v,i) => ({title:v,value:i})).filter(f => f.value !== 0));
				break;
			case 'payment_type':
				setChildrenItems(paymentTypeName.map((v,i) => ({title:v,value:i})).filter(f => f.value !== 0));
				break;
			case 'promo_code_type':
				setChildrenItems(promoCodeTypeName.map((v,i) => ({title:v,value:i})).filter(f => f.value !== 0));
				break;
			case 'delivery_area':
				setChildrenItems(deliveryAreas.map((v,i) => ({title:v.name,value:v._id})));
				break;
			case 'promo_code':
				setChildrenItems(promocodes.map((v,i) => ({title:v.name,value:v._id})));
				break;
			case 'client_status':
				setChildrenItems(loyalities.map((v,i) => ({title:v.name,value:v._id})));
				break;
			default:
				setChildrenItems(condition.children||null);
				break;
		}
		modalChildrenOpen();
	}
	const childSelect = (condition) => {
		if (temp.children) {
			const children = {children:[...temp.children,conditionPrepare(condition)]};
			setTemp({...temp,...children});
		}
		else {
			const value = temp.value;
			if (value) {
				if (value.indexOf(condition.value) === -1) value.push(condition.value);
			}
			else value = [condition.value];
		}
		childrenHide();
	};
	const childRemove = (i) => {
		const children = temp.children;
		children.splice(i,1);
		setTemp({...temp,children});
	};
	const conditionRemove = (idx) => {
		const c = modalType === 'conditions' ? conditions : actions;
		c.splice(idx,1);
		if (modalType === 'conditions') setConditions([...c]);
		else setActions([...c]);
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = baskets.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					points:s.points,
					conditions:s.conditions,
					actions:s.actions,
					dateStart:s.properties?.period?.start,
					dateEnd:s.properties?.period?.end,
					sortOrder:s.options?.sortOrder,
					isStop:s.options?.isStop,
					forClientOnly:s.options?.forClientOnly,
					status:s.status
				};
				await Baskets.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		basketsGet();
		cancel();
		return true;
	};
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const pointSelect = (point) => {
		const p = basketPoints.find(f => f.id === point._id);
		if (p) setBasketPoints(basketPoints.filter(f => f.id !== point._id));
		else setBasketPoints([...basketPoints, {id:point._id,name:point.name}]);
	};
	const nameGet = (v) => <>
		{v.name}
		{v.properties.period?.start || v.properties.period?.end ?
				<span>
					{v.properties.period?.start ? `с ${moment(v.properties.period.start).format('DD.MM.YYYY')}` : null}
					{v.properties.period?.end ? (v.properties.period?.start ? ' до ' : 'до ') + moment(v.properties.period.end).format('DD.MM.YYYY'): null}
				</span>
			: null}
	</>;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Правила корзины"
							type="marketing"
							data={baskets}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name',func:nameGet},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							rowControlItems={[
								{title:(v) => v.status===commonStatus.ACTIVE?'Отключить':'Включить',class:(v) => v.status===commonStatus.ACTIVE?'inactive':'active',action:(v) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(v._id) : basketChangeStatusOn(v._id)}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте первое<br/>правило</>}
							contentShowTitle={name}
							contentShow={<>
								{description ? 
									<div className="product-view-row-simple">
										{description}
									</div> : null}
								<PointsSelect isShow={true} selected={basketPoints} />
								<h4>Условия</h4>
								<div className="current-conditions-actions">
									{dataViewRender('conditions')}
								</div>
								<h4>Действия</h4>
								<div className="current-conditions-actions">
									{dataViewRender('actions')}
								</div>
								<h4>Параметры</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Начало действия</span>
									<div>{dateStart?moment(dateStart).format('DD.MM.YYYY'):'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Окончание действия</span>
									<div>{dateEnd?moment(dateEnd).format('DD.MM.YYYY'):'–'}</div>
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Не применять прочие акции и скидки</span>
									<div>{isStop?'Да':'Нет'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Доступно только при создание заказа клиентом</span>
									<div>{forClientOnly?'Да':'Нет'}</div>
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название правила" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
								<Accordion title="Условия" info={conditions.length?Orfo.counters.conditions(conditions.length):'Условия не заданы'} isShow={conditions.length!==0||true}>
									{conditions.length ?
											<div className="current-conditions-actions">
												{dataViewRender('conditions')}
											</div>
										:
											<div className="product-edit-row">
												Условия не заданы
											</div>
									}
									<div className="product-edit-row">
										<div className="product-edit-btn" onClick={() => conditionsShow('conditions')}>{conditions.length ? 'Изменить условия' : 'Добавить условия'}</div>
									</div>
								</Accordion>
								<Accordion title="Действия" info={conditions.length?Orfo.counters.actions(actions.length):'Действия не заданы'} isShow={actions.length!==0||true}>
									{actions.length ?
											<div className="current-conditions-actions">
												{dataViewRender('actions')}
											</div>
										:
											<div className="product-edit-row">
												Действия не заданы
											</div>
									}
									<div className="product-edit-row">
										<div className="product-edit-btn" onClick={() => conditionsShow('actions')}>{actions.length ? 'Изменить действия' : 'Добавить действия'}</div>
									</div>
								</Accordion>
								<PointsSelect isEdit={true} points={points} selected={basketPoints} onSelect={pointSelect} />
								<Accordion title="Параметры">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="dateStart" className="label-middle">Начало действия</label>
										<input type="date" id="dateStart" {...bindDateStart} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="dateEnd" className="label-middle">Окончание действия</label>
										<input type="date" id="dateEnd" {...bindDateEnd} />
									</div>
								</Accordion>
								<Accordion title="Дополнительные параметры">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
										<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Не применять прочие акции и скидки</label>
										<img src={isStop ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsStop(!isStop)} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Доступно только при создание заказа клиентом</label>
										<img src={forClientOnly ? imgCheckOn : imgCheckOff} alt="" onClick={() => setForClientOnly(!forClientOnly)} />
									</div>
								</Accordion>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={basketDelete}
							onEdit={basketEdit}
							onAdd={basketAdd}
							onSave={save}
							onSearch={searchBasket}
							onShow={basketShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalCnditionsActions>
				<div className="modal modal-conditions">
					<div className="header">
						<h4>{modalType === 'conditions' ? 'Добавить условие' : 'Добавить действие'}</h4>
						<img src={imgClose} alt="" onClick={conditionsHide} className="btn-close" />
					</div>
					<div className="conditions">
						<div className="sections">
							<div className="inner">
								{(modalType === 'conditions' ? basketRules : basketRuleActions).map((v,i) => <div key={i}>
									<h4>{v.title}</h4>
									{v.items.map((v,i) => <div key={i} className={`section${condition?.type===v.type?' section-select':''}`} onClick={() => conditionSelect(v, null)}>
										{v.title}
									</div>)}
								</div>)}
							</div>
						</div>
						<div className="content">
							<div className={`condition${condition?'':' condition-view'}`}>
								{condition ?
										<>
											<h3>{condition.title}</h3>
											<div className="condition-block">
												<div className="condition-content">
													{dataFormRender(condition, 0, false)}
												</div>
												<div className="buttons">
													<button type="button" onClick={conditionHide} className="btn-cancel">Отменить</button>
													<button type="button" onClick={() => conditionsApply()} className="btn-accept">{conditionIndex === null ? 'Добавить' : 'Изменить'}</button>
												</div>
											</div>
										</>
									:
										(
											(modalType === 'conditions' ? conditions : actions).length ?
													<div className="condition-block">
														<div className="condition-content">
															<h3>{modalType === 'conditions' ? 'Текущие условия' : 'Текущие действия'}</h3>
															{dataViewRender(modalType)}
														</div>
													</div>
												:
													<div className="empty">
														<Empty image={'caregories'} text={<>Для просмотра или добавления условия,<br />выберите одно из списка слева</>} />
													</div>
										)
								}
							</div>
						</div>
					</div>
				</div>
			</ModalCnditionsActions>
			<ModalChildren>
				<div className="modal modal-conditions-children">
					<div className="header">
						<h4>{condition?.title}</h4>
						<img src={imgClose} alt="" onClick={childrenHide} className="btn-close" />
					</div>
					<div className="conditions-list">
						{childrenRender()}
					</div>
				</div>
			</ModalChildren>
			<ModalProducts>
				<div className="modal modal-products">
					<div className="header">
						<h4>Выбрать позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} autoFocus={true} />
					<div className="products">
						<div className="list">
							{products.length ?
									products.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={productsSelected.find(f => f === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => productSelect(v._id)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={productAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalProducts>
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить правило</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={basketChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
		</>
	);
};

export default BasketScreen;