import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	orders		: async (data)	=> await Http.request('reports', httpMethods.POST, data),
	costprice	: async (data)	=> await Http.request('reports/costprice', httpMethods.POST, data),
	purchases	: async (data)	=> await Http.request('reports/purchases', httpMethods.POST, data),
	raw			: async (data)	=> await Http.request('reports/raw', httpMethods.POST, data),
	abc			: async (data)	=> await Http.request('reports/abc', httpMethods.POST, data),
	profit		: async (data)	=> await Http.request('reports/profit', httpMethods.POST, data),
	clients		: async (data)	=> await Http.request('reports/clients', httpMethods.POST, data)
};


export {
	get
};